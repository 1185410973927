import React, { useEffect, useState } from "react";
import { auth, db } from "../utils/firebase";
import { useNavigate, useLocation } from "react-router-dom";
import { getDocs, getDoc, collection, doc } from "firebase/firestore";
import FileUploader from "../Components/FileUploader";
import "./grantprofile.css";
import { LocalizationProvider, MobileTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField, Box } from "@mui/material";
import dayjs from "dayjs";
import Autocomplete from "@mui/material/Autocomplete";
import { generateFirestoreAutoId } from "../Contexts/utilFunctions";
import { useDispatch } from "react-redux";
import NavBar from "../Components/NavBar";
import { addClient, updateProposalInfo } from "../Redux/slices/clientSlice";
const baseurl = process.env.REACT_APP_API_URL;



function Main() {
	const dispatch = useDispatch();
	const location = useLocation();
	const [profileName, setProfileName] = useState("");
	const [grantProposalForm_id, setGrantProposalForm_id] = useState("");
	const [client_Organization_id, setClient_Organization_id] = useState("");
	const [currentStep, setCurrentStep] = useState(1);
	const [childData, setChildData] = useState(null);
	const [setEdited, setIsEdited] = useState(false);
	const [formData, setFormData] = useState({
		Grant_Proposal_Name: "",
		User_name: "",
		Company_Name: "",
		User_role: "",
		Company_Website: "",
		Mission_Statement: "",
		Vision_Statement: "",
		Target_Users_Market: "",
		Focused_Industry: "",
		Problem_Statement: "",
		Solution_Statement: "",
		Programs_Services: "",
		Track_record: "",
		Current_funding: "",
		Budget: "",
		Stakeholders: "",
		Product_Features: "",
		Goals_Objectives_Outcomes: "",
		Long_Term_Plan: "",
		Partners_Collaborators_1: "",
		Partners_Collaborators_2: "",

		Team_Member_1: "",
		Team_Member_Role_1: "",
		Team_Member_Education_1: "",
		Team_Member_1_LinkedIn: "",
		Team_Member_Interests_Hobbies_1: "",
		Team_Member_Super_Power_Skills_1: "",

		Team_Member_2: "",
		Team_Member_Role_2: "",
		Team_Member_Education_2: "",
		Team_Member_2_LinkedIn: "",
		Team_Member_Interests_Hobbies_2: "",
		Team_Member_Super_Power_Skills_2: "",

		Team_Member_3: "",
		Team_Member_Role_3: "",
		Team_Member_Education_3: "",
		Team_Member_3_LinkedIn: "",
		Team_Member_Interests_Hobbies_3: "",
		Team_Member_Super_Power_Skills_3: "",

		Team_Member_4: "",
		Team_Member_Role_4: "",
		Team_Member_Education_4: "",
		Team_Member_4_LinkedIn: "",
		Team_Member_Interests_Hobbies_4: "",
		Team_Member_Super_Power_Skills_4: "",

		Grant_Name: "",
		Grant_Company_Name: "",
		Grant_Amount: null,
		Grant_Requirements: "",
		Due_Date: null,
		Due_Time:null,
		Due_Time_Zone: "",
		AM_PM: "",
		Grant_Company_Website: "",
		Grant_Application_Website: "",
		Grant_Company_Mission_Statement: "",
		Grant_Company_Vision_Statement: "",
		Grant_Company_Target_Market: "",
		Grant_Company_Category_Industry: "",
		Agency_Type: "",
		Geographic_Focus: "",
		Grant_Company_LinkedIn: "",

		Judges_Name: "",
		Judges_Role: "",
		Judges_Partnerships_Collaborations_1: "",
		Judges_Partnerships_Collaborations_2: "",
		Judges_LinkedIn: "",
		Judges_More_Relevant_info: "",
	});
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [overwriteConfirmed, setOverwriteConfirmed] = useState(false);
	const navigate = useNavigate();
	const user = auth.currentUser;
	const uid = user ? user.uid : null;
	const totalSteps = 3;
	const getUSTimeZones = () => {
		const timeZones = Intl.supportedValuesOf("timeZone").filter((zone) =>
			zone.startsWith("America/")
		);
		return timeZones.map((timeZone) => {
			const now = new Date();
			const formatter = new Intl.DateTimeFormat("en-US", {
				timeZone,
				timeZoneName: "short",
			});
			const parts = formatter.formatToParts(now);
			const gmtOffset = parts.find(
				(part) => part.type === "timeZoneName"
			).value;
			return {
				label: `(${gmtOffset}) ${timeZone
					.replace("America/", "")
					.replace("_", " ")}`,
				value: timeZone,
			};
		});
	};
	const usTimeZones = getUSTimeZones();

	useEffect(() => {
		setGrantProposalForm_id(generateFirestoreAutoId());
		setClient_Organization_id(generateFirestoreAutoId());
		const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		const defaultTimeZone = usTimeZones.find(
			(zone) => zone.value === userTimeZone
		);
		if (defaultTimeZone) {
			setFormData((prevData) => ({
				...prevData,
				Due_Time_Zone: defaultTimeZone.value,
			}));
		}
	}, []);

	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged((user) => {
			if (!user) {
				navigate("/login");
			}
		});
		return () => unsubscribe();
	}, []);

	const handleChange = (e) => {
		const { id, value } = e.target;
		setFormData((prevData) => ({ ...prevData, [id]: value }));
		setIsEdited(true);
	};

	const handleDateChange = (data) => {
		if (data && data.isValid && data.isValid()) {
			// Use data.toDate() if it is a valid dayjs object
			setFormData({ ...formData, Due_Date: data.toDate() });
		} else {
			console.error("Invalid date object:", data);
			setFormData({ ...formData, Due_Date: null }); // Handle invalid dates
		}
		setIsEdited(true);
	};

	const addCollaborator = async (type) => {
		let user_id = localStorage.getItem("user_id");
		let data = {}; 

		if (type === "User_Collaborators") {
			data = {
				type: "User_Collaborator",
				user_id: user_id,
				collaborator_id: client_Organization_id,
				userCollaborator_data: {
					name: "",
					email: "",
					phone: "",
					role: "",
					GrantProfileForms: [grantProposalForm_id],
				},
			};
		} else {
			data = {
				type: "Client_Organization",
				user_id: user_id,
				collaborator_id: client_Organization_id,
				collaborator_data: {
					organization_name: formData.Company_Name,
					contact_person: formData.User_name,
					email: "",
					phone: "",
					website: formData.Company_Website,
				},
			};
		}
		dispatch(
			addClient({
				client_id:
					data.collaborator_data.collaborator_id || client_Organization_id,
				contact_person: "",
				organization_name: data.collaborator_data.organization_name,
				email: "",
				phone: "",
				website: data.collaborator_data.website,
			})
		);
		setClient_Organization_id(data.collaborator_data.collaborator_id);
		localStorage.setItem("LastClient", client_Organization_id);
		try {
			const response = await fetch(`${baseurl}/add-collaborator`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(data),
			});
			const result = await response.json();
			console.log("Collaborator added successfully:", result);
			return result;
		} catch (error) {
			console.error("Error adding collaborator:", error);
		}
	}; 

	useEffect(() => {
		const currentProposal = location?.state?.currentProposal;

		if (currentProposal) {
			if (currentProposal.Due_Date) {
				currentProposal.Due_Date = dayjs(currentProposal.Due_Date);
			}
			if (currentProposal.Due_Time) {
				currentProposal.Due_Time = dayjs(currentProposal.Due_Time);
			}
			setFormData(currentProposal);
			setProfileName(currentProposal.Grant_Proposal_Name);
		} else {
		}

	}, []);

	function changeStep(num) {
		setCurrentStep(num);
	}

	const handleNext = () => {
		const requiredInputs = document.querySelectorAll("input[required]");
		const isEmpty = Array.from(requiredInputs).some(
			(input) => !input.value.trim()
		);
		if (isEmpty) {
			alert("Please fill in the required fields. (*)");
			return;
		}
		setCurrentStep((currentStep) => currentStep + 1);
		console.log(currentStep);
	};


	const whiteBorderDatePickerStyles = {
		"& .MuiButtonBase-root": {
			color: "#D3D3D3",
		},
		"& .MuiOutlinedInput-root": {
			"& fieldset": {
				border: "1.5px solid #19039E",
			},
			"&:hover fieldset": {
				borderColor: "#D3D3D3",
			},
			"&.Mui-focused fieldset": {
				borderColor: "#D3D3D3",
			},
		},
	};

	const renderstepFields = (step) => {
		switch (step) {
			case 1:
				return (
					<div>
						<table>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Applicant's Name *
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Applicant's Name *"
											id="User_name"
											value={formData.User_name}
											onChange={handleChange}
											required
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">Company Name *</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Company Name *"
											id="Company_Name"
											value={formData.Company_Name}
											onChange={handleChange}
											required
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">Role *</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Role *"
											id="User_role"
											value={formData.User_role}
											onChange={handleChange}
											required
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Company Website URL
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Company Website URL"
											id="Company_Website"
											value={formData.Company_Website}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Mission Statement
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Mission Statement"
											id="Mission_Statement"
											value={formData.Mission_Statement}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Vision Statement
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Vision Statement"
											id="Vision_Statement"
											value={formData.Vision_Statement}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Users/target Market *
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Users/target Market *"
											id="Target_Users_Market"
											value={formData.Target_Users_Market}
											onChange={handleChange}
											required
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">Industry *</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Industry *"
											id="Focused_Industry"
											value={formData.Focused_Industry}
											onChange={handleChange}
											required
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Problem Statement *
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Problem Statement *"
											id="Problem_Statement"
											value={formData.Problem_Statement}
											onChange={handleChange}
											required
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Solution Statement *
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Solution Statement *"
											id="Solution_Statement"
											value={formData.Solution_Statement}
											onChange={handleChange}
											required
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Programs, Services, and Product Features *
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Programs, Services, and Features *"
											id="Programs_Services"
											value={formData.Programs_Services}
											onChange={handleChange}
											required
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Track record and past success
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Track record and past success"
											id="Track_record"
											value={formData.Track_record}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Current funding sources
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Current funding sources"
											id="Current_funding"
											value={formData.Current_funding}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">Budget</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Budget"
											id="Budget"
											value={formData.Budget}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr></tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Goals, Objectives, and Outcomes
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Goals, Objectives, and Outcomes"
											id="Goals_Objectives_Outcomes"
											value={formData.Goals_Objectives_Outcomes}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Long-term sustainability plan
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Long-term sustainability plan"
											id="Long_Term_Plan"
											value={formData.Long_Term_Plan}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Relevant Partnerships and Collaborators 1
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Relevant Partnerships and Collaborators 1"
											id="Partners_Collaborators_1"
											value={formData.Partners_Collaborators_1}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Relevant Partnerships and Collaborators 2
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Relevant Partnerships and Collaborators 2"
											id="Partners_Collaborators_2"
											value={formData.Partners_Collaborators_2}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
						</table>
					</div>
				);
			case 2:
				return (
					<div>
						<table>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs font-bold">
										Team Member 1 Name *
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Team Member 1 Name *"
											id="Team_Member_1"
											value={formData.Team_Member_1}
											onChange={handleChange}
											required
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs font-bold">
										Team Member 2 Name
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Team Member 2 Name"
											id="Team_Member_2"
											value={formData.Team_Member_2}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">Role *</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Role *"
											id="Team_Member_Role_1"
											value={formData.Team_Member_Role_1}
											onChange={handleChange}
											required
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">Role</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Role"
											id="Team_Member_Role_2"
											value={formData.Team_Member_Role_2}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">Education</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Education"
											id="Team_Member_Education_1"
											value={formData.Team_Member_Education_1}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">Education</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Education"
											id="Team_Member_Education_2"
											value={formData.Team_Member_Education_2}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">LinkedIn URL</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="LinkedIn URL"
											id="Team_Member_1_LinkedIn"
											value={formData.Team_Member_1_LinkedIn}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">LinkedIn URL</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="LinkedIn URL"
											id="Team_Member_2_LinkedIn"
											value={formData.Team_Member_2_LinkedIn}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Super Power/Skills
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Super Power/Skills"
											id="Team_Member_Super_Power_Skills_1"
											value={formData.Team_Member_Super_Power_Skills_1}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Super Power/Skills
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Super Power/Skills"
											id="Team_Member_Super_Power_Skills_2"
											value={formData.Team_Member_Super_Power_Skills_2}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr></tr>
						</table>
						<table>
							<tr>
								<td>
									<label
										type="text"
										placeholder="_"
										id="_"
										onChange={handleChange}
									/>
								</td>
								<td>
									<label
										type="text"
										placeholder="_"
										id="_"
										onChange={handleChange}
									/>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs font-bold">
										Team Member 3 Name
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Team Member 3 Name"
											id="Team_Member_3"
											value={formData.Team_Member_3}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs font-bold">
										Team Member 4 Name
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Team Member 4 Name"
											id="Team_Member_4"
											value={formData.Team_Member_4}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">Role</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Role"
											id="Team_Member_Role_3"
											value={formData.Team_Member_Role_3}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">Role</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Role"
											id="Team_Member_Role_4"
											value={formData.Team_Member_Role_4}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">Education</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Education"
											id="Team_Member_Education_3"
											value={formData.Team_Member_Education_3}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">Education</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Education"
											id="Team_Member_Education_4"
											value={formData.Team_Member_Education_4}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">LinkedIn URL</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="LinkedIn URL"
											id="Team_Member_3_LinkedIn"
											value={formData.Team_Member_3_LinkedIn}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">LinkedIn URL</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="LinkedIn URL"
											id="Team_Member_4_LinkedIn"
											value={formData.Team_Member_4_LinkedIn}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Super Power/Skills
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Super Power/Skills"
											id="Team_Member_Super_Power_Skills_3"
											value={formData.Team_Member_Super_Power_Skills_3}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Super Power/Skills
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Super Power/Skills"
											id="Team_Member_Super_Power_Skills_4"
											value={formData.Team_Member_Super_Power_Skills_4}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr></tr>
						</table>
					</div>
				);
			case 3:
				return (
					<div>
						<table>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">Grant Name *</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Grant Name *"
											id="Grant_Name"
											value={formData.Grant_Name}
											onChange={handleChange}
											required
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Grant Company Name *
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Grant Company Name *"
											id="Grant_Company_Name"
											value={formData.Grant_Company_Name}
											onChange={handleChange}
											required
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p
										style={{
											textAlign: "left",
											color: "#b0aeae",
											fontSize: "12px",
											margin: "0px",
											paddingLeft: "0px",
										}}
									>
										Due Time *
									</p>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<Box>
											<Box>
												<MobileTimePicker
													value={formData.Due_Time ? dayjs(formData.Due_Time) : null} // Use null if no time is set
													onChange={(newValue) => {
														if (newValue && newValue.isValid && newValue.isValid()) {
															const formattedTime = newValue.format("HH:mm"); // Format the time as "HH:mm"
															setFormData((prevData) => ({
																...prevData,
																Due_Time: formattedTime, // Update formData with formatted time
															}));
														} else {
															setFormData((prevData) => ({
																...prevData,
																Due_Time: null, // Reset Due_Time if the value is invalid or cleared
															}));
														}
													}}
													slots={{
														textField: (params) => (
															<TextField
																{...params}
																required
																sx={{
																	width: "165px",
																	backgroundColor: "#11002e",
																	"& .MuiOutlinedInput-root": {
																		"& fieldset": {
																			borderColor: "#1a039e",
																			borderWidth: "1.5px",
																		},
																		"&:hover fieldset": {
																			borderColor: "#d4d4d4",
																		},
																		"&.Mui-focused fieldset": {
																			borderColor: "#d4d4d4",
																		},
																	},
																	"& input": {
																		color: "white",
																	},
																}}
																InputProps={{
																	...params.InputProps,
																	sx: {
																		height: "33px",
																		padding: "9px",
																		"& .MuiSvgIcon-root": {
																			color: "#d4d4d4",
																		},
																	},
																}}
															/>
														),
													}}
													PopperProps={{
														sx: {
															overflow: "hidden",
														},
													}}
												/>
											</Box>
										</Box>
									</LocalizationProvider>
								</td>

								<td>
									<p
										style={{
											textAlign: "left",
											color: "#b0aeae",
											fontSize: "12px",
										}}
									>
										Time Zone *
									</p>
									<Autocomplete
										options={usTimeZones}
										className="custom-timezone"
										getOptionLabel={(option) =>
											`${option.label.split(") ")[1]} (${option.label
												.split(") ")[0]
												.substring(1)})`
										}
										renderInput={(params) => (
											<TextField
												{...params}
												placeholder="Due Time Zone *"
												inputProps={{
													...params.inputProps,
													style: { height: "15px", padding: "0px" },
												}}
												style={{ width: "200px", marginLeft: "0px" }}
												InputLabelProps={{ style: { color: "white" } }}
											/>
										)}
										value={
											usTimeZones.find(
												(zone) => zone.value === formData.Due_Time_Zone
											) || null
										}
										onChange={(event, newValue) => {
											setFormData((prevData) => ({
												...prevData,
												Due_Time_Zone: newValue ? newValue.value : "",
											}));
										}}
										required
										sx={whiteBorderDatePickerStyles}
									/>
								</td>
							</tr>
							<tr>
								<td>
									<p
										style={{
											textAlign: "left",
											color: "#b0aeae",
											fontSize: "12px",
										}}
									>
										Due Date *
									</p>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											value={
												formData.Due_Time ? dayjs(formData.Due_Time) : null
											}
											onChange={handleDateChange}
											showDaysOutsideCurrentMonth
											renderInput={(params) => (
												<TextField
													{...params}
													InputLabelProps={{ style: { color: "white" } }}
												/>
											)}
											required
											sx={whiteBorderDatePickerStyles}
										/>
									</LocalizationProvider>
								</td>
							</tr>

							<td>
								<p className="ml-0 text-[#b0afaf] text-xs">Grant Amount *</p>
								<div className="form-item-styles">
									<input
										className="transparent-placeholder"
										type="number"
										placeholder="Grant Amount"
										id="Grant_Amount"
										value={formData.Grant_Amount}
										onFocus={(e) => {
											if (formData.Grant_Amount === 0) {
												e.target.value = "";
											}
										}}
										onBlur={(e) => {
											if (e.target.value === "") {
												setFormData((prevData) => ({
													...prevData,
													Grant_Amount: 0,
												}));
											}
										}}
										onChange={(e) => {
											const newValue = Number(e.target.value);
											setFormData((prevData) => ({
												...prevData,
												Grant_Amount: isNaN(newValue) ? 0 : newValue,
											}));
										}}
										required
									/>
								</div>
							</td>
							<td>
								<p className="ml-0 text-[#b0afaf] text-xs">
									Grant Application Webpage URL
								</p>
								<div className="form-item-styles">
									<input
										className="transparent-placeholder"
										type="text"
										placeholder="Grant Application Webpage URL"
										id="Grant_Application_Website"
										value={formData.Grant_Application_Website}
										onChange={handleChange}
									/>
								</div>
							</td>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Grant Company Category / Industry
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Grant Company Category / Industry"
											id="Grant_Company_Category_Industry"
											value={formData.Grant_Company_Category_Industry}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Grant Company Target Market
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Grant Company Target Market"
											id="Grant_Company_Target_Market"
											value={formData.Grant_Company_Target_Market}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Agency Type (e.g. Federal, State, Local, Organization)
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Agency Type  (e.g. Federal, State, Local, Organization)"
											id="Agency_Type"
											value={formData.Agency_Type}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Geographic Focus (US state)
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Geographic Focus (US state)"
											id="Geographic_Focus"
											value={formData.Geographic_Focus}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Grant Company Mission Statement
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Grant Company Mission Statement"
											id="Grant_Company_Mission_Statement"
											value={formData.Grant_Company_Mission_Statement}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Grant Company Vision Statement
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Grant Company Vision Statement"
											id="Grant_Company_Vision_Statement"
											value={formData.Grant_Company_Vision_Statement}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Grant Company LinkedIn URL
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Grant Company LinkedIn URL"
											id="Grant_Company_LinkedIn"
											value={formData.Grant_Company_LinkedIn}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Grant Company Website URL
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Grant Company Website URL"
											id="Grant_Company_Website"
											value={formData.Grant_Company_Website}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<label type="text" id="_" onChange={handleChange} />
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">Judge's Name</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Judge's Name"
											id="Judges_Name"
											value={formData.Judges_Name}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">Role</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Role"
											id="Judges_Role"
											value={formData.Judges_Role}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										Judge's Linkedin URL
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="Judge's Linkedin URL"
											id="Judges_LinkedIn"
											value={formData.Judges_LinkedIn}
											onChange={handleChange}
										/>
									</div>
								</td>
								<td>
									<p className="ml-0 text-[#b0afaf] text-xs">
										More Relevant info about Grant Admin/Judge
									</p>
									<div className="form-item-styles">
										<input
											className="transparent-placeholder"
											type="text"
											placeholder="More Relevant info about Grant Admin/Judge"
											id="Judges_More_Relevant_info"
											value={formData.Judges_More_Relevant_info}
											onChange={handleChange}
										/>
									</div>
								</td>
							</tr>
						</table>
					</div>
				);
		}
	}; 

	async function handleSubmit(e) {
		e.preventDefault();
		// Check for required inputs
		const requiredInputs = document.querySelectorAll("input[required]");
		const isEmpty = Array.from(requiredInputs).some(
			(input) => !input.value.trim()
		);
		console.log(isEmpty, formData)
		if (isEmpty) {
			alert("Please fill in the required fields. (*)");
			return;
		}
		// Ensure location.state and currentProposal are defined
		const currentProposal = location?.state?.currentProposal;
		const currentClient =
			location?.state?.currentClient || client_Organization_id;
		const userCollaboratorId = generateFirestoreAutoId();
		const formPayload = {
			form: { ...formData, Grant_Proposal_Name: profileName },
			user_collaborator_id: userCollaboratorId,
			grantProposalForm_id: grantProposalForm_id,
			client_Organization_id:
				currentClient?.client_id || client_Organization_id,
			profile: profileName,
			user: uid,
			Company_Name: formData.Company_Name, // Explicitly ensure Company_Name is included
			Grant_Proposal_Name: profileName,
		};
		console.log(formPayload)
		// Determine conditions for API call
		let shouldSubmit = false;

		if (!currentProposal) {
			console.log("No currentProposal found in location.state");
			const firstClient = await addCollaborator("User_Collaborator");
			formPayload.user_collaborator_id =
				firstClient?.collaborator_id || userCollaboratorId;
			shouldSubmit = true;
		} else if (currentProposal.id !== currentClient?.client_id) {
			if (currentProposal.Grant_Proposal_Name !== profileName) {
				setOverwriteConfirmed(false);
				shouldSubmit = true;
			} else {
				setOverwriteConfirmed(true);
			}
		} else {
			shouldSubmit = true;
		}

		// Make the API call if necessary
		if (shouldSubmit) {
			try {
				const response = await fetch(`${baseurl}/form`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(formPayload),
				});

				if (response.ok) {
					const clientID = currentClient?.client_id || client_Organization_id;
					dispatch(
						updateProposalInfo({ clientID, formData, grantProposalForm_id })
					);
					navigate("/grant-assistant", {
						state: {
							from: "grantform",
							grantProposalForm_id,
							clientID,
							profileName,
							formData,
						},
					});
				} else {
					console.log("Error updating the data");
				}
			} catch (error) {
				console.error("Network error:", error);
			}
		}
	} 

	//getting the data from the file upload component then setting the state with the data
	function handleChildData(data) {
		// Update the Due_Date and Due_Time if they exist in the incoming data
		if (data.Due_Date) {
			data.Due_Date = null; //dayjs();
		}
		if (data.Due_Time) {
			data.Due_Time = null; //dayjs();
		}

		// Update the form data state with the modified data
		setFormData((prevData) => ({
			...prevData,
			...data,
			Company_Name: prevData.Company_Name || data.Company_Name, // Keep old Company_Name if it exists
			Grant_Proposal_Name: data.profileName || prevData.Grant_Proposal_Name, // Use profileName
		}));
		setProfileName(data.profileName);
	} 
	return (
		<div className="App">
			{overwriteConfirmed && (
				<dialog
					open
					className="space-y-2 bg-[#1f1f1f] text-[#dedede] p-10 w-[528px] h-[200px] z-10 rounded-xl"
				>
					<h3 className="text-2xl font-bold">www.grantaide.com</h3>
					<p className="">Proposal name already exists. Rename proposal.</p>
					<form method="dialog" className="flex justify-end px-20">
						{/* possible hover animation if needed hover:bg-[#244E6E] */}
						<button
							className="mt-4 text-[#9eb5de] border border-[#306791] rounded-3xl py-2 px-4 "
							onClick={() => setOverwriteConfirmed(false)}
						>
							Cancel
						</button>
					</form>
				</dialog>
			)}
			<NavBar />
			<div className="bg-black h-full w-full flex flex-row text-white pt-12">
				<aside className="left-sidemenu">
					<div
						className={`left-side-menu-button ${
							currentStep === 1 ? "active" : ""
						}`}
						onClick={() => changeStep(1)}
					>
						<span>1</span>
						Company Info
					</div>
					<div
						className={`left-side-menu-button ${
							currentStep === 2 ? "active" : ""
						}`}
						onClick={() => changeStep(2)}
					>
						<span>2</span>
						Team Info
					</div>
					<div
						className={`left-side-menu-button ${
							currentStep === 3 ? "active" : ""
						}`}
						onClick={() => changeStep(3)}
					>
						<span>3</span>
						Grant Info
					</div> 
				</aside>
				<section className="form">
					<div className="top">
						<h2 className="flex flex-col items-center pt-4">
							<input
								type="text"
								value={profileName}
								placeholder="Name Your Grant Proposal *"
								onChange={(e) => {
									const newValue = e.target.value;
									setProfileName(newValue); // Update profileName
									setFormData((prevData) => ({
										...prevData,
										Grant_Proposal_Name: newValue, // Update Grant_Proposal_Name in formData
									})); 
								}}
								className="section-name-input"
								style={{ backgroundColor: "#2e0f68" }}
								autoFocus
								required
							/>
						</h2>
					</div>

					<form
						onSubmit={handleSubmit}
						style={{
							display: "flex",
							flexDirection: "column",
							gap: "1em",
							padding: "20px",
						}}
					>
						{renderstepFields(currentStep)}
					</form>

					{currentStep < totalSteps && (
						<div className="button" onClick={handleNext}>
							<label type="button"> Next </label>
						</div>
					)}
					{currentStep === totalSteps && (
						<div className="button" onClick={handleSubmit}>
							<label type="button"> Save and Start</label>
						</div>
					)}
				</section>

				<FileUploader
					from={"grantaide"}
					uid={uid}
					client_Organization_id={
						location?.state?.currentClient.client_id || client_Organization_id
					}
					grantProposalForm_id={grantProposalForm_id}
					sendDataToParent={handleChildData}
				/>
			</div>
		</div>
	);
}

export default Main;
