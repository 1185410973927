import React from "react";
import { Link } from "react-router-dom";

export default function Header() {
  const headerLinks = [
    {
      url: "/form",
      name: "profile-btn",
      value: "Profiles",
      hidden: true, 
    },
    {
      url: "/grant-assistant",
      name: "grant-btnn",
      value: "Grants",
      hidden: true, 
    },
    {
      url: "/signup",
      name: "sign up",
      value: "Sign Up",
      hidden: true, 
    },
    {
      url: "/login",
      name: "sign in",
      value: "Sign In",
    },
    {
      url: "#pricing-plans",  
      name: "pricing-plans",
      value: "Pricing Plans",
      hidden: true, 
    },
  ];

  return (
    /*<div className="sticky top-0 flex flex-row gap-2 h-12 bg-gradient-to-l from-green-400 from-12.76% via-sky-500 from-35.58% via-purple-500 from-63.06%  to-fuchsia-500 to-89.1% ...">*/
    <div className="sticky top-0 flex flex-row gap-2 h-12 bg-gradient-to-l from-green-400 via-sky-500 via-35% via-purple-500 via-63% to-fuchsia-500">
      <div className="flex-1 align-left pr-24">
        <h2 className="pl-10 py-2 font-medium  text-white min-[320px]:text-xl sm:text-xl md:text-2xl lg:text-3xl sm:justify-center sm:items-center">
          Grant<span className="text-white font-bold">AI</span>de
        </h2>
      </div>
      <div className="flex-1 pr-3 min-[320px]:text-xs min-[320px]:p-0">
        <div className="flex flex-row min-[320px]:gap-2 gap-4 px-auto py-2 justify-center items-center  sm:pl-5 md:pl-24 lg:pl-[460px]">
        {headerLinks
            .filter((link) => !link.hidden) // Filter out hidden links
            .map((link, index) => (
              <Link to={link.url} key={index}>
                <input
                  type="submit"
                  className="bg-[#EBEAF5] shadow-xl rounded-xl px-3 py-1 w-[95px] sm:text-xs md:text-base sm:p-1 font-[roboto] font-normal text-purple-900"
                  name={link.name}
                  value={link.value}
                />
              </Link>
            ))}
        </div>
      </div> 
    </div>
  );
}
