import { React, useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { marked } from "marked";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { convertMarkdownToHtml,styles } from "../utils/convertMarkdownToHTML";

export default function ChatMessage({
  message,
  parentRefreshClick,
  isLoading,
  displayMode,
  wordCount,
  charCount,
  handleDisplayModeChange,
  showCount,
  from,
  user_id,
  client_organization_id,
  grant_proposal_form_id
}) {

  const [messageRefreshed, setMessageRefreshed] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [tabList, setTabList] = useState([]);
  const [messageText, setMessageText] = useState(message.message);
  const messageRef = useRef(null);
  const [currentWordCount, setCurrentWordCount] = useState(0);
  const [currentCharCount, setCurrentCharCount] = useState(0);
  const baseurl = process.env.REACT_APP_API_URL;
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
      ["link", "image"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  
  //functions
  const calculateWNS = (message) => {
    const words = message.trim().split(/\s+/);
    const characters = message.trim().length;
    setCurrentWordCount(words.length);
    setCurrentCharCount(characters);
  };

  const handleContentInput = (e) => {
    calculateWNS(e.target.innerText);
  };

  const updateSelected = (selectedIdx) => {
    setTabList((prev) => {
      let newList = [...prev];
      let newObj = { selected: false };
      newList = newList.slice(selectedIdx, 1, newObj);
      return newList;
    });
  };

  const refreshClick = (e) => {
    parentRefreshClick();
    setMessageRefreshed(true);
    setTabList((prev) => {
      if (prev) {
        prev.map((elementInPrev) => {
          if (elementInPrev) {
            elementInPrev.selected = false;
          }
        });
        return [...prev, { selected: true }];
      }
    });
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    if (message.user === "bot") {
      try {
        const response = await fetch(`${baseurl}/update-chat`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: user_id,
            client_id: client_organization_id,
            proposal_id: grant_proposal_form_id,
            message: messageText, // Use messageText instead of messageRef
            question: message.question,
            timestamp: message.timestamp || new Date().toISOString(),
          }),
        });

        const data = await response.json();
        if (data.success) {
          setIsEditing(false);
        }
      } catch (error) {
        console.error("Error updating message:", error);
      }
    }
  };
  
  const handleCancelClick = () => {
    setMessageText(message.message);
    setIsEditing(false);
  };


  //UseEffects
  useEffect(() => {
    if (messageRef.current && message.user === "bot") {
      messageRef.current.focus();
    }
  }, [message.user]);

  useEffect(() => {
    if (messageRef.current) {
      messageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [message.message]);

  useEffect(() => {
    calculateWNS(message.message); 
  }, [message.message]);

  useEffect(() => {
    const styleSheet = document.createElement("style");
    styleSheet.textContent = styles;
    document.head.appendChild(styleSheet);

    return () => {
      document.head.removeChild(styleSheet);
    };
  }, []);
  
  return (
    <div
      className={`chat-message ${message.user === "bot" && "chatgpt overscroll-contain scroll-smooth custom-scrollbar"
        } w-full`}
    >
      <div className="chat-message-center overscroll-contain w-full scroll-smooth custom-scrollbar">
        <div className="avatar-and-btns-div">
          {from != "copilot" && <>
            <div
              className={` ${message.user === "bot" &&
                "chatgpt "
                }`}
            >
              {message.user !== "bot" && message.message} {/* Add this line */}
            </div>
            {message.user === "bot" && showCount && (
              <div className="word-char-count-container">
                <div className="flex flex-col gap-1 word-char-count-btn">
                  <button
                    className="word-count"
                    onClick={() => handleDisplayModeChange("words")}
                  >
                    w
                  </button>
                  <button
                    className="character-count"
                    onClick={() => handleDisplayModeChange("characters")}
                  >
                    c
                  </button>
                </div>
                <div className="mt-1">
                  <span className="total-number-field">
                    {displayMode === "words"
                      ? currentWordCount
                      : currentCharCount}
                  </span>
                </div>
              </div>
            )}
          </>}
        </div>

        {message.user === "bot" && messageRefreshed && (
          <div className="tab-bar-div w-full">
            {tabList.map((tab, idx) => {
              if (tab) {
                return (
                  <div
                    onClick={() => updateSelected(idx)}
                    key={idx}
                    className={classNames("tab", tab.selected && "selected")}
                  />
                );
              }
            })}
          </div>
        )}
        <div>

          <div className="editor-container">
            {message.user === "bot" && (
              <>
                <ReactQuill
                  key={isEditing ? "editing" : "reading"}
                  value={convertMarkdownToHtml(messageText)}
                  onChange={setMessageText}
                  modules={isEditing ? modules : { toolbar: false }}
                  readOnly={!isEditing}
                  theme={isEditing ? "snow" : "bubble"}
                  className="[&_.ql-stroke]:!stroke-[white] [&_.ql-fill]:!fill-[white] [&_.ql-picker]:!text-[white] [&_.ql-editor]:!text-[15px]"
                />


                <div className="message-controls">
                  {!isEditing ? (
                    <button
                      className="edit-button text-green-500 underline px-4 py-1"
                      onClick={handleEditClick}
                    >
                      Edit
                    </button>
                  ) : (
                    <div className="edit-controls flex gap-2 mt-2">
                      <button
                        className="save-button text-green-500 underline px-4"
                        onClick={handleSaveClick}
                      >
                        Save
                      </button>
                      <button
                        className="cancel-button text-red-500 underline px-4"
                        onClick={handleCancelClick}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

        </div>
      </div>
    </div>
  );
};