import React, { useState } from "react";
import { createUserWithEmailAndPassword, deleteUser } from "firebase/auth";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { auth, db } from "../../utils/firebase";
import { addDoc, collection } from "firebase/firestore";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

//Accordion used for EU user form
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// used for the coutnry dropdown in the EU user form
import { useMemo } from "react";
import countryList from 'react-select-country-list'
function Signup() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentError, setCurrentError] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [streetAddress, setStreetAddress] = useState('');
  const [aptSuite, setAptSuite] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('')
  const options = useMemo(() => countryList().getData(), [])
  const [postcode, setPostcode] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [companyAddress, setCompanyAddress] = useState('');
  const [acceptButtonClicked, setAcceptButtonClicked] = useState(false)
  const [showAcceptMessage, setShowAcceptMessage] = useState(false)
  const [openDialog, setOpenDialog] = useState(false);
  const baseurl = process.env.REACT_APP_API_URL;

  const queryParams = new URLSearchParams(location.search);
  const plan = queryParams.get("plan");
  const priceId = queryParams.get("price_id");
  const affiliateId = queryParams.get("affiliate_id");
  const handleDialogOpen = () => setOpenDialog(true);
  const handleDialogClose = () => setOpenDialog(false);

  const handleAcceptClicked = () => {
    setShowAcceptMessage(false)
    setAcceptButtonClicked(!acceptButtonClicked)
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!acceptButtonClicked) {
      setShowAcceptMessage(true);
      alert('You must accept the terms and conditions to proceed.');
      return;
    }

    if (!plan && !priceId) {
      handleDialogOpen();
      return;
    }
    
    await createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        try {
          await addDoc(collection(db, "UsersPersonalInfo"), {
            email: email,
            phone: phone,
            name: name,
            id: userCredential.user.uid,
          });

          // Payment session logic
          const body = { user_id: userCredential.user.uid, price_id: priceId };
          if (affiliateId) body.affiliate_id = affiliateId;

          const response = await fetch(`${baseurl}/create_payment_session`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
          });

          const data = await response.json();
          if (data.url) {
            window.location.assign(data.url);
          } else {
            console.error("Error: Payment URL not found");
          }
        } catch (e) {
          console.error("Error adding user data or creating session:", e);
          await deleteUser(userCredential.user);
        }
      })
      .catch((error) => {
        console.log("Error during user creation:", error);
        setCurrentError(error);
      });
  };


  return (
    <div className="login-page h-fit overflow-y-auto">
      <div className="wrapper max-h-screen" >
        <Dialog open={openDialog} onClose={handleDialogClose}>
          <DialogTitle  >
            {"Missing Plan Information"}
          </DialogTitle>
          <DialogContent  >
            {"You need to select a plan before signing up. Please choose a plan to proceed."}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              sx={{
                color: "black",
                backgroundColor: "#A85101",
                border: "1px solid white",
                "&:hover": { backgroundColor: "#813E00" },
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                handleDialogClose();
                navigate("/", { state: { scrollTo: "pricing-plans" } }); // Navigate to the pricing page
              }}
              sx={{
                color: "black",
                backgroundColor: "#A85101",
                border: "1px solid white",
                "&:hover": { backgroundColor: "#813E00" },
              }}
            >
              Go to Pricing
            </Button>
          </DialogActions>
        </Dialog>
        <div className="title">Sign up</div>
        <div>
          {currentError && <p className="text-center text-red-700  my-2">{currentError.code.replace('auth/', '') === "weak-password" ? `Password should be at least 6 characters` : `${currentError.code.replace('auth/', '')}`}</p>}
        </div>
        <form onSubmit={onSubmit}>
          <div className="field">
            <input
              type="name"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <label htmlFor="name">Name</label>
          </div>
          <div className="field">
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="email">Email Address</label>
          </div>

          <div className="field">
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="password">Password</label>
          </div>
          {/* Conditional rendering based on password length */}
          {password.length < 6 && password.length > 0 && (
            <p className="text-center text-yellow-700 text-sm">Password should be at least 6 characters</p>
          )}

          <div className="field">
            <input
              type="text"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            <label htmlFor="phone">Phone</label>
          </div>

          {/* For EU Users */}
          <p className="my-4 text-red-600 text-sm text-center">
            If you are an EU or UK business customer, please include a company address and contact person in accordance with local regulations.
          </p>
          <Accordion sx={{
            boxShadow: 'none',
            '&:before': {
              display: 'none',
            },
            '& .MuiAccordionSummary-root': {
              minHeight: '48px',
              '&.Mui-expanded': {
                minHeight: '48px',
              }
            },
            '& .MuiAccordionSummary-content': {
              margin: '12px 0',
              '&.Mui-expanded': {
                margin: '12px 0',
              }
            }
          }}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography component="span"> EU/UK Requirement Form </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="address-form">
                
                <div className="field">
                  <input
                    type="text"
                    id="contactPerson"
                    value={contactPerson}
                    onChange={(e) => setContactPerson(e.target.value)}
                  />
                  <label htmlFor="contactPerson">Contact Person</label>
                </div>

                <div className="field">
                  <input
                    type="text"
                    id="streetAddress"
                    value={streetAddress}
                    onChange={(e) => setStreetAddress(e.target.value)}
                  />
                  <label htmlFor="streetAddress">Street address</label>
                </div>

                <div className="field">
                  <input
                    type="text"
                    id="aptSuite"
                    value={aptSuite}
                    onChange={(e) => setAptSuite(e.target.value)}
                  />
                  <label htmlFor="aptSuite">Apt, suite, etc (optional)</label>
                </div>

                <div className="field">
                  <input
                    type="text"
                    id="city"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                  />
                  <label htmlFor="city">City</label>
                </div>

                <div className="field my-4 flex items-center justify-center space-x-4">
                  {!country && <p>Country</p>}
                    <FormControl fullWidth>
                    <Select
                      labelId="country-select-label"
                      id="country-select"
                      value={country}
                      label="Country"
                      onChange={(e) => setCountry(e.target.value)}
                      sx={{
                        borderRadius: '10px',
                        width: "auto",
                        height: "50px",
                        padding: "0 10px",
                        fontSize: "16px",
                        color: "#4158D0",
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          borderColor: '#4158D0'
                        }
                      }}
                    >
                        <MenuItem value="" >
                          Select a country
                        </MenuItem>
                        {options.map(({ label, value }) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                </div>

                <div className="field">
                  <input
                    type="text"
                    id="postcode"
                    value={postcode}
                    onChange={(e) => setPostcode(e.target.value)}
                  />
                  <label htmlFor="postcode">ZIP/ postcode</label>
                </div>

              </div>
            </AccordionDetails>
          </Accordion>

            {/* end of EU user feild  */}
          <div className="terms mt-4">
            <label htmlFor="remember-me">
              By signing up, you agree to the{" "}
              <Link to="/termsofservice" target="_blank" className="font-bold underline text-[#6d56cc]">
                Terms of Service
              </Link>{" "}
              and{" "}
              <Link to="/privacypolicy" target="_blank" className="font-bold underline text-[#6d56cc]">
                Privacy Policy
              </Link>
            </label>
          </div>

          <div className="flex flex-col justify-center p-3">
            <label className="flex flex-row justify-center gap-2">
              I Accept
              <input
                type="checkbox"
                checked={acceptButtonClicked}
                onChange={handleAcceptClicked}
              />
            </label>
            {showAcceptMessage && <p className="flex flex-row justify-center text-red-700 font-medium text-md animate-pulse">Click accept to sign up.</p>}
          </div>

          <div className="field">
            <input type="submit" id="submit SignUp" value="Sign Up" />
          </div>

          <div className="signup-link">
            Already Registered?{" "}
            <Link to="/login">Login</Link>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Signup;


//useSaveAffiliateId() called in App.js not setting the affiliate_id