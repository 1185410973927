const convertMarkdownToHtml = (text) => {
    if (!text) return '';

    // First, normalize line endings
    text = text.replace(/\r\n/g, '\n');

    // Handle lists with various markers
    const listMarkers = [
        /^[-–—•⁃∙⋅●○◦⦁⭐✦✧❋❊✴︎✹※⁜] ?(.*$)/gm,  // Various bullet points and dashes
        /^\* ?(.*$)/gm,                           // Asterisk
        /^\+ ?(.*$)/gm,                          // Plus sign
        /^\d+\. ?(.*$)/gm,                       // Numbered lists
        /^\d+\) ?(.*$)/gm,                       // Numbered lists with parentheses
        /^[a-z]\. ?(.*$)/gm,                     // Alphabetical lists
        /^[a-z]\) ?(.*$)/gm,                     // Alphabetical lists with parentheses
    ];

    // Process each type of list marker
    listMarkers.forEach(marker => {
        text = text.replace(marker, (match, content) => {
            return `<li>${content.trim()}</li>\n`;
        });
    });

    // Wrap consecutive <li> elements in <ul> or <ol>
    text = text.replace(
        /(<li>.*?<\/li>\n)+/gs,
        match => {
            // Check if it's a numbered list
            if (match.match(/^\d+\./m)) {
                return `<ol>\n${match}</ol>\n`;
            }
            return `<ul>\n${match}</ul>\n`;
        }
    );

    // Convert bold (**text**)
    text = text.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');

    // Convert italic (*text*)
    text = text.replace(/\*(.*?)\*/g, '<i>$1</i>');

    // Convert headers with line breaks
    text = text.replace(/^# (.*$)/gm, '<h1>$1</h1>\n');
    text = text.replace(/^## (.*$)/gm, '<h2>$1</h2>\n');
    text = text.replace(/^### (.*$)/gm, '<h3>$1</h3>\n');

    // Convert links [text](url)
    text = text.replace(/$$([^$$]+)\]$([^)]+)$/g, '<a href="$2">$1</a>');

    // Convert code blocks (```code```)
    text = text.replace(/```([\s\S]*?)```/g, '<pre><code>$1</code></pre>\n');

    // Convert inline code (`code`)
    text = text.replace(/`([^`]+)`/g, '<code>$1</code>');

    // Add line breaks for paragraphs
    text = text.replace(/\n\n/g, '<br><br>');

    return text;
};

const styles = `
  .markdown-content ul, 
  .markdown-content ol {
    margin: 1em 0;
    padding-left: 2em;
  }
  
  .markdown-content li {
    margin: 0.5em 0;
  }
  
  .markdown-content br {
    display: block;
    margin: 0.5em 0;
  }
  
  .markdown-content h1,
  .markdown-content h2,
  .markdown-content h3 {
    margin: 1em 0;
  }
`;
export { convertMarkdownToHtml, styles };