import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import axios from "axios";
import { useDispatch } from "react-redux";
import { updateClient } from "../Redux/slices/clientSlice";

export default function ProposalCard({ item, index, handleClick, clientID }) {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState(null);
  const currentDate = new Date();
  const dueDate = new Date(item.Due_Date);
  const [hours, minutes] = item.Due_Time
    ? item.Due_Time.split(":").map(Number)
    : [0, 0];
  dueDate.setHours(hours, minutes, 0, 0); // Set the time on the date

  const timeDifferenceMillis = dueDate - currentDate;
  const shouldNotify =
    timeDifferenceMillis < 6 * 24 * 60 * 60 * 1000 && timeDifferenceMillis >= 0;
  const userID = localStorage.getItem("user_id");
  const baseurl = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();

  const deleteProposal = async (proposalItem) => {
    const payload = {
      client_Organization_id: clientID,
      user_id: userID,
      grantProposalForm_id: proposalItem.id,
    };

    try {
      const response = await axios.post(`${baseurl}/delete-form`, payload);

      if (response.status === 200) {
        const updatedProposalItem = {
          ...proposalItem,
          is_deleted: true,
        };

        // Dispatch action to update the Redux store
        dispatch(
          updateClient({
            clientId: clientID,
            proposalItemId: proposalItem.id,
            proposal: { ...updatedProposalItem },
          })
        );
      } else {
        console.error("Failed to delete the proposal:", response.data.message);
      }
    } catch (error) {
      console.error("Error deleting proposal:", error);
    }
  };

  const handleDeleteClick = (e, proposalItem) => {
    e.stopPropagation();
    setSelectedProposal(proposalItem);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedProposal(null);
  };

  const handleConfirmDelete = () => {
    if (selectedProposal) {
      deleteProposal(selectedProposal);
    }
    handleDialogClose();
  };

  return (
    <>
      <div
        key={index}
        className={`flex flex-col ${shouldNotify
            ? "w-[260px] rounded-[5px] h-[150px] border-2 border-[#ddd] whitespace-normal break-words p-[5px] m-[6px] flex flex-col overflow-hidden text-[10px] leading-[15px] cursor-pointer bg-[#A85101]  hover:bg-[#853F00] transition-colors duration-300 ease-in"
            : "dashboard-box"
          } p-4 cursor-pointer`}
        onClick={() => handleClick(item.id)}
      >
        <div>
          <div className="flex flex-row text-[16px] line-[18px] text-white font-Radley p-1 justify-between">
            <p
              className="clamp-two-lines leading-5"
              style={{
                height: "2.5em",
              }}
            >
              {item.Grant_Proposal_Name || "no grant proposal name"}
            </p>
            <DeleteIcon
              fontSize="small"
              onClick={(e) => handleDeleteClick(e, item)}
            />
          </div>
          <div className="category2">
            <p className="line-clamp-1">
              Grantor: {item.Grant_Company_Name || " "}
            </p>
            <p className="pt-2 line-clamp-1">Amount: {item.Grant_Amount || "_"}</p>
          </div>
          <div className="text-row flex flex-row gap-9 pt-2 px-0 pb-0">
            <p className="category3 flex-1 pl-[1px]">
              Due:{" "}
              {new Date(item.Due_Date).toLocaleDateString("en-US", {
                month: "short",
                day: "numeric",
                year: "numeric",
              })}
            </p>
            <p className="category3 flex-1">
              {timeDifferenceMillis < 0
                ? "In: Past Due!"
                : `In: ${Math.floor(
                  timeDifferenceMillis / (1000 * 60 * 60 * 24)
                )}d
                  ${Math.floor(
                  (timeDifferenceMillis % (1000 * 60 * 60 * 24)) /
                  (1000 * 60 * 60)
                )}h 
                  ${Math.floor(
                  ((timeDifferenceMillis % (1000 * 60 * 60 * 24)) %
                    (1000 * 60 * 60)) /
                  (1000 * 60)
                )}m`}
            </p>
          </div>
        </div>
      </div>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        sx={{
          "& .MuiDialog-paper": {
            backgroundColor: "black",
            border:"1px solid white",
            color: "white",
          },
        }}
      >
        <DialogTitle sx={{ color: "white" }}>
          {`Do you want to delete proposal "${selectedProposal?.Grant_Proposal_Name}"?`}
        </DialogTitle>
        <DialogContent sx={{ color: "white" }}>
          Deleting this proposal will mark it as deleted and it will not be visible anymore.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            sx={{
              color: "black",
              backgroundColor: "#A85101",
              border: "1px solid white",
              "&:hover": { backgroundColor: "#813E00" },
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            sx={{
              color: "white",
              border: "1px solid white",
              "&:hover": { backgroundColor: "#444" },
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}
