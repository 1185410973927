import React, { useEffect, useState } from "react";
import { auth } from "../../utils/firebase";
import { useNavigate } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import "./dashboard.css";
import ProposalCard from "../../Components/ProposalCard";
import { styled } from "@mui/material/styles";
import CreateNewUserModal from "../../Components/CreateNewUserModal";
import { useLocation } from 'react-router-dom';
import { Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import NavBar from "../../Components/NavBar";
import Collaborators from "../../Components/Collaborators";
import useMediaQuery from '@mui/material/useMediaQuery';
function Dash2() {
  const isSmallScreen = useMediaQuery('(max-width:640px)');
  const isMediumScreen = useMediaQuery('(max-width:1024px)');
  const isLargeScreen = useMediaQuery('(min-width:1025px) and (max-width:1440px)');
  const isXLargeScreen = useMediaQuery('(min-width:1441px)');
  // commented out the called of the fetch Clients function in the useEffect to fetch the clients from the database and opted to call the clients from the redux store
  const clientsStore = useSelector(state => state.clients);

  const [data, setData] = useState([]);
  const [startExistingProfile, setStartExistingProfile] = useState(true);
  const [currentClient, setCurrentClient] = useState("");
  const [clients, setClients] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const baseurl = process.env.REACT_APP_API_URL;

  //MUI styles 
  const AddClientButton = styled(Button)({
    height: "45px",
    color: "white",
    width: "97%",
    fontSize: "18px",
    backgroundColor: "#380093",
    border: "solid 3px #5406D2",
    margin: "5px 0",
  });

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;
        readData(uid);
      }
    });
    if (auth.currentUser) {
      readData(auth.currentUser.uid);
    }
  }, []);

  useEffect(() => {
    const lastClient = localStorage.getItem('LastClient');
    const clientIds = Object.keys(clientsStore);
    // If there is at least one client
    if (clientIds.length > 1) {
      // If the user has only one client
      if (clientIds.length === 1) {
        setCurrentClient({ ...clientsStore[clientIds[0]][Object.keys(clientsStore[clientIds[0]])[0]], client_id: Object.keys(clientsStore[clientIds[0]])[0] });
      }
      // If the user has more than one client
      else {
        // Check if the lastClient exists in the clientsStore
        const foundLastClient = clientIds.some(
          clientId => clientsStore[clientId][lastClient]
        );

        if (lastClient && foundLastClient) {
          setCurrentClient({ ...clientsStore[clientIds[1]][lastClient], client_id: lastClient });
        } else {
          // Fallback to setting the first available client if no last client is found
          setCurrentClient({ ...clientsStore[clientIds[1]][Object.keys(clientsStore[clientIds[1]])[0]], client_id: Object.keys(clientsStore[clientIds[1]])[0] });
        }
      }
    }
  }, [clientsStore]);
  async function readData(uid) {
    try {
      const response = await fetch(`${baseurl}/get-user-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uid: uid }),
      });

      const sortedData = await response.json();
      const clientsData = [];
      const clientID = Object.keys(sortedData.sub_collections)[0];
      const grantForms = sortedData.sub_collections[clientID];

      if (currentClient === clientID || currentClient != "") {
        if (sortedData.sub_collections) {
          for (const [clientId, clientArray] of Object.entries(sortedData.sub_collections)) {
            clientArray.forEach(client => {
              clientsData.push({ id: clientId, ...client });
            });
          }
        }
        setData(clientsData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleNewProfile = () => {
    navigate("/form", { state: { currentClient: location.state?.currentClient, currentProposal: { message: "handeNewProfile" } } });
  };

  const handleClick = (id) => {
    if (startExistingProfile) {
      const proposal = location.state.currentClient.proposals.find(proposal => proposal.id === id);
      navigate("/form", { state: { currentProposal: proposal, currentClient: currentClient } });

    } else {
      alert(
        "Select `Start with an existing profile` if you wish to move with it."
      );
    }
  };


  return (
    <div>
      <NavBar />

      <div className="dash-2 top-12">
        <div className="w-1/5 bg-[#11002e] border-r-4 border-[#5406D2] overflow-auto flex">
          <div className="w-1/6 bg-[#250062] flex">
            {/* <Fab size="small"></Fab> */}
          </div>
          {/* <NewClientSidebar /> */}
          <div className="left-client-sidemenu w-1/5 content-start">
            <Typography
              sx={{ display: "flex", justifyContent: "left" }}
              id="modal-title"
              color="white"
              className="pl-3 pt-8 pb-8 text-xs"
            >
              Client List
            </Typography>
            <AddClientButton
              type="button"
              className=" mt-32   "
              onClick={() => setOpenModal(true)}  // Open the modal on click
            >
              <AddCircleOutlineIcon />

              <div className="text-[16px] pl-1 font-thin lowercase w-fit rounded-md"> <span className="uppercase">N</span>ew <span className="uppercase">C</span>lient</div>

            </AddClientButton>

            <CreateNewUserModal
              open={openModal}
              onClose={() => setOpenModal(false)}
              setClients={setClients}
            />
            {/* <Link to="/all-client-dashboard">
              <ViewAllClientButton
                type="button"
                onClick={handleOpen}
              >
                <div>View All Clients</div>
              </ViewAllClientButton>
            </Link> */}

            <div className="client-list">
              {clientsStore && Object.keys(clientsStore).length > 0 ? (
                (() => {
                  // Get the second key dynamically
                  const secondKey = Object.keys(clientsStore)[1];

                  // Access the nested object based on the second key
                  const clients = clientsStore[secondKey]
                    ? Object.entries(clientsStore[secondKey]).map(([key, value]) => {
                      return {
                        ...value,
                        client_id: key, // Add client_id property
                      };
                    })
                    : [];
                  return clients.length > 0 ? (
                    clients.map(client => (
                      <div
                        key={client.client_id}
                        className={`rounded-md h-[34px] w-[98.5%] text-white text-[15px] my-1 flex items-center px-2.5 border-[3px] ${currentClient?.organization_name
                          ? (currentClient.client_id === client.client_id
                            ? 'bg-[#784DBD] border-[#DBC5FF]'
                            : 'bg-[#380093] border-[#5406D2]')
                          : 'bg-[#380093] border-[#5406D2]'
                          }`}
                        onClick={() => {
                          // if (currentClient.organization_name !== client.organization_name) {


                          // }
                        }}
                      >
                        <p>
                          {isSmallScreen
                            ? client.organization_name.length > 10
                              ? `${client.organization_name.slice(0, 5)}...`
                              : client.organization_name
                            : isMediumScreen
                              ? client.organization_name.length > 12
                                ? `${client.organization_name.slice(0, 10)}...`
                                : client.organization_name
                              : isLargeScreen
                                ? client.organization_name.length > 25
                                  ? `${client.organization_name.slice(0, 15)}...`
                                  : client.organization_name
                                : isXLargeScreen
                                  ? client.organization_name.length > 40
                                    ? `${client.organization_name.slice(0, 20)}...`
                                    : client.organization_name
                                  : client.organization_name}
                        </p>
                      </div>
                    ))
                  ) : (
                    <p>No clients available</p>
                  );
                })()
              ) : (
                <p>No clients available</p>
              )}
            </div>
          </div>
        </div>
        <div className="w-4/5 pl-3">
          {/* <div className="create-container"> */}
          {/* <div className="create" onClick={handleNewProfile}>
              <AddCircleOutlineIcon />
              Create a new Profile
            </div> */}
          {/* <input type="text" className="search-bar text-white" placeholder="Search" /> */}
          {/* <div className="info-text"> This will create a new profile</div> */}
          {/* </div> */}
          <div className="flex items-center justify-between mb-4 mt-3">
            <button className="button2">
              <AddCircleOutlineIcon />
              <div className="pl-1" onClick={handleNewProfile}>Create new Proposal</div>
            </button>
            {/* <input type="text" className="search-bar text-white" placeholder="Search" /> */}
          </div>
          <div className="create-container">
            <div
              className="existing flex justify-between items-center"
              onClick={() => setStartExistingProfile(true)}
            >
              {" "}
              Start with an existing profile from below
              <Collaborators headerText={"Client Collaborator"} type={"Client_Collaborator"} currentClient={location?.state?.currentClient} />
            </div>
          </div>

          <div className="flex w-full mt-1">
            <div className="dash p-1 d-flex items-center gap-3 bg-red-400 w-fit">
              {location.state.currentClient.proposals &&
                Array.isArray(location.state.currentClient.proposals) &&
                location.state.currentClient.proposals.map((item, index) =>
                  (item.is_deleted === undefined || !item.is_deleted) && ( 
                    <ProposalCard 
                      key={index} 
                      item={item} 
                      index={index} 
                      handleClick={() => handleClick(item.id)} 
                      clientID={location.state.currentClient?.client_id} 
                    /> 
                  ) 
                )}
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

// { message } needs to be passed to "ChatMessage", holding for testing

export default Dash2;
