import React, { useState, useEffect, useRef } from "react";
import LoadSpinner from "../Pages/LoadSpinner/LoadSpinner";
import Tooltip from '@mui/material/Tooltip';
export default function ChatInput(props) {
  const textareaRef = useRef(null);
  const {
    input,
    setInput,
    isLoading,
    handleSubmit,
    handleDisplayModeChange,
    handleGptVersionChange,
    displayMode,
    wordCount,
    charCount,
  } = props;

  // const [showHelp, setShowHelp] = useState(false);
  const [activeButton, setActiveButton] = useState("gpt-4o-mini");

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInput(value);
    adjustTextAreaHeight();
    // setShowHelp(value.startsWith("/"));
  };


  const handleKeyDown = (e) => {
    if (e.keyCode === 13 || 16) {
      if (e.key === "Enter" && !e.shiftKey) {
        // e.preventDefault();
        handleSubmit();
      } else if (e.key === "Enter" && e.shiftKey) {
        // Insert a newline character at the cursor position
        // e.preventDefault();
        const { selectionStart, selectionEnd } = textareaRef.current;
        const newValue =
          input.slice(0, selectionStart) + "\n" + input.slice(selectionEnd);
        setInput(newValue);
        adjustTextAreaHeight();
        // Set the cursor position after the new line
        setTimeout(() => {
          textareaRef.current.selectionStart =
            textareaRef.current.selectionEnd = selectionStart + 1;
        }, 0);
      }
    } else {
      e.preventDefault();
    }
  };

  const adjustTextAreaHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;
    textarea.style.height = "auto"; // Reset height to recalculate
    textarea.style.height = `${textarea.scrollHeight}px`; // Set to scroll height
    textarea.scrollTop = textarea.scrollHeight; // Keep scroll at the bottom
  };

  useEffect(() => {
    adjustTextAreaHeight(); // Adjust on initial render
  }, []);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus(); // Focus the textarea on initial render
    }
  }, []);

  return (
    <form className="w-3/5">
      <div className="relative flex h-full max-w-full flex-1 flex-col">
       {/* {showHelp && (
          <div className="Commands w-[93%] z-50 text-white mb-[15%] ml-5 border-[#5406D2] scroll-padding-right-4">
            <ul className="table-of-content">
              <li className="flex flex-col">
                <div className="pt-2 font-semibold ">/giveaway create</div>
              </li>
              <li className="flex flex-col">
                <div className="pt-2 font-semibold">/giveaway delete</div>
              </li>
              <li className="flex flex-col">
                <div className="pt-2 font-semibold">/giveaway end</div>
              </li>
              <li className="flex flex-col">
                <div className="pt-2 font-semibold">/giveaway list</div>
              </li>
              <li className="flex flex-col">
                <div className="pt-2 font-semibold">/giveaway reroll</div>
              </li>
              <li className="flex flex-col">
                <div className="pt-2 font-semibold">/help</div>
              </li>
              <li className="flex flex-col">
                <div className="pt-2 font-semibold">/invitecodes</div>
              </li>
            </ul>
          </div>
        )} */}
        <div className="absolute bottom-0 left-0 right-0 z-20">
          <div className="flex w-full items-center pl-2 pr-5">
            <div className="flex w-[98%] flex-col gap-1.3 rounded-[26px] transition-colors bg-[#000033]">
              <div className="flex flex-col items-center justify-center text-white mx-4 mb-6 border-4 border-blue-800">
                <div className="flex min-w-0 flex-1 flex-col py-2 max-w-[96%] w-full justify-self">
                  <textarea
                    value={input}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    className="m-0 resize-none bg-transparent text-white border-2 border-purple-900 focus:ring-0 focus-visible:ring-0 px-6 w-full mt-3"
                    // placeholder="Type / to access special AI features"
                    style={{ minHeight: "10px", maxHeight: "120px" }} // Set a minimum height
                    ref={textareaRef}
                  ></textarea>
                </div>
                <div className="flex flex-row justify-between w-[95%]">
                  <div className="flex flex-col items-center text-center justify-self-start gap-1.5">
                    <div className="word-char-count-btn justify-center items-center mt-0 mb-2">
                      {/* <button
                        className="word-count items-center text-sm"
                        onClick={(e) => {
                          handleDisplayModeChange("words");
                        }}
                      >
                        w
                      </button>
                      <button
                        className="character-count items-center text-sm"
                        onClick={(e) => {
                          handleDisplayModeChange("characters");
                        }}
                      >
                        c
                      </button> */}
                      {/* <div className="m-0">
                        <span className="total-number-field">
                          {displayMode === "words" ? wordCount : charCount}
                        </span>
                      </div> */}
                      <div>
                        <button
                          className={`gpt-f items-center text-sm ${activeButton === "gpt-4o-mini" ? "bg-[#5406d2]" : "bg-[#2C0075]"}`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleGptVersionChange("gpt-4o-mini")
                            setActiveButton("gpt-4o-mini")
                          }}
                        >
                          A1
                        </button>
                        <button
                          className={`gpt-fo items-center text-sm ${activeButton === "gpt-4o" ? "bg-[#5406d2]" : "bg-[#2C0075]"}`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleGptVersionChange("gpt-4o")
                            setActiveButton("gpt-4o")
                          }}
                        >
                          A2
                        </button>
                      </div>
                    </div>
                  </div>
                  {isLoading && <LoadSpinner />}
                  <button
                    type="button"
                    className="bg-gradient-to-l from-green-400 from-12.76% via-sky-500 from-35.58% via-purple-500 from-63.06% to-fuchsia-500 to-89.1% flex justify-self-end h-6 w-16 align-middle text-center items-center justify-center ml-7 justfiy-self-end rounded-md"
                    onClick={(e) => {
                      handleSubmit();
                    }}
                    disabled={isLoading}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
